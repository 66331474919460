
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButtons, IonButton, menuController } from "@ionic/vue";
import { refresh, attach, menu } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { dateFormat } from "../services/utils";
import { openToast } from "../services/toast";

import apiDocumenti from "../services/documenti";

export default {
    name: "Documenti",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButtons,
        IonButton,
    },
    setup() {
        const router = useRouter();

        const loading = ref(false);
        const presenze = ref([]);
        const documenti = ref([]);
        const documenti_generali = ref([]);
        const documenti_cedolino = ref([]);
        const documenti_contratto = ref([]);

        const showGenerali = ref(true);
        const showCedolini = ref(false);

        const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const selectedDocumentType = ref("1"); //Documenti generali di default

        const openMenu = () => {
            menuController.open("app-menu");
        };

        function setActiveDocuments(type) {
            selectedDocumentType.value = type;
            if (type === "generali") {
                showGenerali.value = true;
                showCedolini.value = false;
            } else {
                showCedolini.value = true;
                showGenerali.value = false;
            }
        }

        function setAttachmentUrl(documento) {
            return `${process.env.VUE_APP_BASE_URL}uploads/${documento.documenti_dipendenti_file}`;
        }

        /**
         * ! Get all documenti
         */
        async function loadDocuments() {
            loading.value = true;
            try {
                documenti.value = await apiDocumenti.getDocumenti(dipendenteID);
                documenti_generali.value = documenti.value.filter((document) => document.documenti_dipendenti_categoria == "1");
                documenti_cedolino.value = documenti.value.filter((document) => document.documenti_dipendenti_categoria == "2");
                documenti_contratto.value = documenti.value.filter((document) => document.documenti_dipendenti_categoria == "3");
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei documenti", "toast_danger");
            }
            loading.value = false;
        }
        //    loadDocuments();

        /**
         * ! Get all documenti categorie
         */
        const categorieDocumenti = ref([]);
        async function loadDocumentiCategorie() {
            loading.value = true;
            try {
                categorieDocumenti.value = await apiDocumenti.getCategorieDocumenti();
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle categorie di documenti", "toast_danger");
            }
            loading.value = false;
        }
        loadDocumentiCategorie();

        /**
         * ! Filter all documetn to show based on current selected document type
         */
        const documentsToShow = computed(() => {
            return documenti.value.filter((documento) => {
                if (documento.documenti_dipendenti_categoria === selectedDocumentType.value) return documento;
            });
        });

        /**
         * ! Check id document is expired to show badge with label "Scaduto"
         */
        function documentoScaduto(documento) {
            const scadenza = documento.documenti_dipendenti_data_scadenza;
            const dataScadenza = moment(scadenza).valueOf();
            const dataAttuale = moment().valueOf();

            if (dataScadenza < dataAttuale) {
                return true;
            }
        }

        /**
         * ! Set correct background for data scadenza if expired
         */
        const scadenzaDocumento = computed(() => {
            return (scadenza) => {
                let className = "";
                const dataScadenza = moment(scadenza).valueOf();
                const dataAttuale = moment().valueOf();

                if (dataScadenza < dataAttuale) {
                    className = "danger";
                } else {
                    className = "light";
                }
                return className;
            };
        });

        /**
         * ! Passing id to retrive detail in PresenzaDetail page
         */
        function openDetail(id: string) {
            router.push(`tab2/${id}`);
        }

        onMounted(() => {
            //GET DOCUMENTI DATA
            loadDocuments();
        });

        return {
            loading,
            document,
            attach,
            presenze,
            documenti,
            scadenzaDocumento,
            dateFormat,
            refresh,
            openDetail,
            //Nuovi campi
            documentoScaduto,
            loadDocuments,
            openMenu,
            menu,
            //Suddivisione documenti
            documenti_generali,
            documenti_cedolino,
            setActiveDocuments,
            showGenerali,
            showCedolini,
            setAttachmentUrl,
            //Nuova gestione categorie documenti
            categorieDocumenti,
            documentsToShow,
            selectedDocumentType,
        };
    },
};
